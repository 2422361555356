<template>
  <div id="modal-menu">
    <div class="modal-content">
      <button
        type="button"
        class="close close-left"
        data-dismiss="modal"
        aria-label="Close"
        @click="closeAndResetStoreState"
      >
        <span aria-hidden="true">×</span>
      </button>
      <form v-if="product" id="form-add-menu" method="post" action>
        <div class="modal-body">
          <div class="modal-head pb-20">
            <div
              class="image-cover sides"
              v-bind:style="{
                backgroundImage: `url('${product.image.desktop_detail}')`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%'
              }"
            >
              <img
                v-if="product.image"
                v-bind:src="
                  product.image.desktop_thumbnail
                    ? product.image.desktop_thumbnail
                    : $const.IMG_PIZZA_DEFAULT
                "
                class="mobile-only"
              />
            </div>
            <h1 class="t22 fw600 title-name">{{ product.name }}</h1>

            <p
              class="t14 fw300 pr10 pt10 des"
              v-if="product.marketing_description"
            >{{ product.marketing_description }}</p>
            <!-- <p class="t13 fw300 pr10 pt10 text-black des"  v-if="product.main_description"> -->
            <!--   <nl2br tag="p" :text="product.main_description"/> -->
            <!-- </p> -->

            <span class="add-toppings-str" v-if="addExtraTopping && addExtraTopping.length > 0">
              <span class="text-green">
                <strong>
                  {{ $t('cart.add') }}
                  {{
                  convertString(addExtraTopping.map(f => fixFreeToppingName(f.name)).join(', '))
                  }}
                </strong>
              </span>
              <span class="content text-capitalize"></span>
            </span>

            <span
              class="remove-toppings-str"
              v-if="removeYourTopping && removeYourTopping.length > 0"
            >
              <span class="text-orange">
                <strong>
                  {{ $t('cart.remove') }}
                  {{
                  convertString(removeYourTopping.map(f => fixFreeToppingName(f.name)).join(', '))
                  }}
                </strong>
              </span>
              <span class="content text-capitalize"></span>
            </span>
          </div>
          <div class="flex1auto selector-container" style="max-height: calc(100vh - 515px);">
            <!-- ############################## Choose size & crust ########################## -->
            <div class="box-select-variant-addon" v-if="!choosedName">
              <p
                class="fw600 t17 mb10 text-black bg-grey-lightest md:bg-transparent p10 pl20"
                style="font-family: 'SharpSemiBold Sans'"
                v-if="
                  product &&
                    product.first_layers &&
                    product.first_layers.length > 0
                "
              >{{ $t('menu.select_size_crust') }}</p>
              <div v-for="item in product.first_layers" :key="genUniqueKey(item)">
                <div
                  class="t15 fw600 heading-line-right mt10 mb10 text-black p10 pl20 pr20 bg-white md:bg-transparent"
                >{{ item.name }}</div>
                <div
                  v-for="subitem in item.second_layers"
                  :key="genUniqueKey(subitem)"
                  class="variant-addon-item option display-flex t14 p10 pl20 pr20 text-black bg-grey-lightestest md:bg-transparent active"
                  @click="chooseVariant(item, subitem)"
                >
                  <div class="flex1 display-flex items-center justify-start text-black fw400">
                    <div>
                      <img
                        v-if="subitem.image"
                        width="35"
                        v-bind:alt="'..........'"
                        v-bind:src="
                          subitem.image.desktop_thumbnail
                            ? subitem.image.desktop_thumbnail
                            : $const.IMG_CRUST_DEFAULT
                        "
                        :style="{ color: '#fff' }"
                        class="option---icon"
                      />
                      <img
                        v-else
                        width="35"
                        v-bind:src="$const.IMG_CRUST_DEFAULT"
                        class="option---icon"
                      />
                    </div>
                    <div class="option---content">
                      <div>{{ subitem.name }}</div>
                      <div class="option__long-description t13 fw400"></div>
                    </div>
                  </div>
                  <div
                    class="fw600 text-right pl10 pr10 flex-center"
                    v-if="
                      product &&
                        product.first_layers &&
                        product.first_layers.length >= 1
                    "
                  >
                    {{
                    $t('currency_display', {
                    currency: $const.CURRENCY,
                    amount: formatNumDisplay(
                    sumNum(product.price, item.price, subitem.price)
                    )
                    })
                    }}
                  </div>
                  <div class="fw600 text-right pl10 pr10 flex-center" v-else>
                    {{
                    $t('currency_display', {
                    currency: $const.CURRENCY,
                    amount: formatNumDisplay(product.price)
                    })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ################################################################################### -->
            <!-- ############################ Topping ###################################### -->
            <div class="pt15 pb10 pl20 pr20 box-topping-contain" v-if="choosedName">
              <div class="variant-addon-choosen">
                <div class="display-flex t14">
                  <span class="flex1 name fw400 text-black">
                    {{
                    convertString(choosedName)
                    }}
                  </span>
                  <a
                    @click="changeSize"
                    class="cursor-pointer cl-78838c border-b"
                  >{{ $t('button_change') }}</a>
                </div>
                <hr />
              </div>

              <div class="menu-toppings option-group">
                <div class="topping-header">{{ $t('toppings_change_header') }}</div>
                <!-- Default Sauce -->
                <collapse class="option-group" v-model="showSauceAccordion">
                  <div
                    class="extra-sauce option option-sauce md:bg-transparent"
                    v-if="!showSauceChooser"
                    @click="toggleSauceChooser"
                  >
                    <div class="xs-mt-20">
                      <div class="extra-sauce-option flex1">
                        <label class="xs-items-center rounded label-radio" :for="choosedSauce.uuid">
                          <input
                            class="xs-block rounded xs-p-5 radio ExtraSauce"
                            :id="choosedSauce.uuid"
                            name="ExtraSauce"
                            checked
                            type="radio"
                          />
                          <i class="pull-left mt-12"></i>
                          <span class="xs-mr-auto xs-items-center">
                            <i class="xs-hide md-block xs-mr-10"></i>
                            <span
                              class="fw400 t14"
                              style="display: inline-block; vertical-align: middle;"
                            >{{ choosedSauce.name }}</span>
                          </span>
                          <div class="pull-right">
                            <span
                              class="extra-sauce-price t15 fw600 cl-525252"
                              v-if="getPriceTopping(choosedSauce.prices) > 0"
                            >
                              +
                              {{
                              $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(
                              getPriceTopping(choosedSauce.prices)
                              )
                              })
                              }}
                            </span>
                            <a
                              class="cl-78838c border-b fw400 t14 xs-align-middle"
                            >{{ $t('button_change') }}</a>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="extra-sauce choose-sauce pt-12" v-if="showSauceChooser">
                    <span class="t17" style="font-family: 'SharpSemiBold Sans'">
                      {{
                      $t('toppings_your_sauce')
                      }}
                    </span>

                    <div
                      class="option option-sauce md:bg-transparent"
                      v-for="(topping, index) in extraTopping.filter(
                        t => t.special == 2
                      )"
                      :key="'extraSauce_' + index"
                    >
                      <div class="xs-mt-20">
                        <div class="extra-sauce-option">
                          <label
                            class="xs-items-center rounded label-radio"
                            :for="topping.uuid"
                            @click="chooseSauce(topping)"
                          >
                            <input
                              class="xs-block rounded xs-p-5 radio ExtraSauce"
                              :id="topping.uuid"
                              name="ExtraSauce"
                              :value="topping.sku"
                              :checked="choosedSauce.sku == topping.sku"
                              type="radio"
                            />
                            <i class="pull-left mt-12"></i>
                            <span class="xs-mr-auto xs-items-center">
                              <i class="xs-hide md-block xs-mr-10"></i>
                              <span class="fw400 t14">{{ topping.name }}</span>
                            </span>
                            <span
                              class="pull-right extra-sauce-price t15 fw600 cl-525252 xs-align-middle"
                              v-if="getPriceTopping(topping.prices) > 0"
                            >
                              +
                              {{
                              $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(
                              getPriceTopping(topping.prices)
                              )
                              })
                              }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse>

                <!-- Default Cheese -->
                <collapse class="option-group" v-model="showCheeseAccordion">
                  <div
                    class="extra-cheese option option-cheese md:bg-transparent"
                    v-if="!showCheeseChooser"
                    @click="toggleCheeseChooser"
                  >
                    <div class="xs-mt-20">
                      <div class="extra-cheese-option flex1">
                        <label
                          class="xs-items-center rounded label-radio"
                          :for="choosedCheese.uuid"
                        >
                          <input
                            class="xs-block rounded xs-p-5 radio ExtraCheese"
                            :id="choosedCheese.uuid"
                            name="ExtraCheese"
                            checked
                            type="radio"
                          />
                          <i class="pull-left mt-12"></i>
                          <span class="xs-mr-auto xs-items-center">
                            <i class="xs-hide md-block xs-mr-10"></i>
                            <span
                              class="fw400 t14"
                              style="display:inline-block; vertical-align:middle"
                            >{{ choosedCheese.name }}</span>
                          </span>
                          <div class="pull-right">
                            <span
                              class="extra-cheese-price t15 fw600 cl-525252"
                              v-if="getPriceTopping(choosedCheese.prices) > 0"
                            >
                              +
                              {{
                              $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(
                              getPriceTopping(choosedCheese.prices)
                              )
                              })
                              }}
                            </span>
                            <a
                              class="cl-78838c border-b fw400 t14 xs-align-middle"
                            >{{ $t('button_change') }}</a>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="extra-cheese choose-cheese pt-12" v-if="showCheeseChooser">
                    <span class="t17" style="font-family: 'SharpSemiBold Sans'">
                      {{
                      $t('toppings_your_cheese')
                      }}
                    </span>

                    <div
                      class="option option-cheese md:bg-transparent"
                      v-for="(topping, index) in extraTopping.filter(
                        t => t.special == 1
                      )"
                      :key="'extraCheese_' + index"
                    >
                      <div class="xs-mt-20">
                        <div class="extra-cheese-option">
                          <label
                            class="xs-items-center rounded label-radio"
                            :for="topping.uuid"
                            @click="chooseCheese(topping)"
                          >
                            <input
                              class="xs-block rounded xs-p-5 radio ExtraCheese"
                              :id="topping.uuid"
                              name="ExtraCheese"
                              :value="topping.sku"
                              :checked="choosedCheese.sku == topping.sku"
                              type="radio"
                            />
                            <i class="pull-left mt-12"></i>
                            <span class="xs-mr-auto xs-items-center">
                              <i class="xs-hide md-block xs-mr-10"></i>
                              <span class="fw400 t14">{{ topping.name }}</span>
                            </span>
                            <span
                              class="pull-right extra-cheese-price t15 fw600 cl-525252 xs-align-middle"
                              v-if="getPriceTopping(topping.prices) > 0"
                            >
                              +
                              {{
                              $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(
                              getPriceTopping(topping.prices)
                              )
                              })
                              }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse>

                <div v-if="hasNonSpecialToppings(yourTopping)">
                  <div
                    class="topping-header"
                    @click="showToppingMinus = !showToppingMinus"
                    :class="{ active: showToppingMinus }"
                  >
                    {{ $t('your_toppings') }}
                    <i data-v-38def44e class="fa fa-caret-up"></i>
                  </div>
                  <collapse v-model="showToppingMinus">
                    <div
                      class="your-toppings text-black"
                      v-if="yourTopping && yourTopping.length > 0"
                    >
                      <div
                        class="text-red text-center pt10 pb10"
                        v-if="removeYourTopping.length >= maxRemoval && maxRemoval > 0"
                      >
                        {{
                        $t('modal.topping_removal_info', { max: maxRemoval })
                        }}
                      </div>
                      <transition-group
                        name="fade"
                        v-on:before-leave="beforeLeaveTopping"
                        v-on:after-leave="afterLeaveTopping"
                      >
                        <div
                          class="your-toppings-item display-flex t14 p10 cursor-pointer option visible"
                          v-for="(topping, index) in yourTopping"
                          :key="'yourTopping' + index"
                          @click="changeTopping(topping, 1, index)"
                        >
                          <div class="flex1 fw400">
                            <img
                              class="ml5"
                              v-bind:src="topping.image.desktop_thumbnail ? topping.image.desktop_thumbnail : $const.TOPPING.DEFAULT_IMAGE"
                              width="24"
                              height="2"
                            />
                            <span class="pl-5">
                              {{
                                convertString(fixFreeToppingName(topping.name))
                              }}
                            </span>
                          </div>
                          <div
                            class="text-right pl10 pr10 topping-price fw600 cl-525252"
                            v-if="
                              !topping.default &&
                                getPriceTopping(topping.prices) > 0
                            "
                          >
                            {{
                            $t('currency_display', {
                            currency: $const.CURRENCY,
                            amount: formatNumDisplay(
                            getPriceTopping(topping.prices)
                            )
                            })
                            }}
                          </div>
                          <div class="bold text-right pl10 pr10" style="align-self: center;">
                            <img
                              v-if="!topping.isDisable"
                              src="../../assets/images/icons/remove.svg"
                              class="cursor-pointer"
                              alt="remove"
                            />
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </collapse>
                </div>

                <div v-if="hasNonSpecialToppings(extraTopping) && extraTopping.some(t => t.special == 0)">
                  <div
                    class="topping-header"
                    @click="showToppingPlus = !showToppingPlus"
                    :class="{ active: showToppingPlus }"
                  >
                    {{ $t('add_toppings') }}
                    <i data-v-38def44e class="fa fa-caret-up"></i>
                  </div>
                  <collapse v-model="showToppingPlus">
                    <div
                      class="add-toppings text-black"
                      v-if="extraTopping && extraTopping.length > 0"
                    >
                      <transition-group name="fade">
                        <div
                          v-for="(extopping, index) in extraTopping.filter(
                            t =>
                              t.special != '1' &&
                              t.special != '2' &&
                              t.special != '3'
                          )"
                          :key="'extraTopping_' + index"
                          class="add-toppings-item display-flex t14 p10 cursor-pointer option bg-grey-lightestest md:bg-transparent"
                          v-bind:class="{ disable: extopping.isDisable }"
                          @click="changeTopping(extopping, 2, index)"
                        >
                          <div class="flex1 fw400">
                            <img
                              class="ml5"
                              v-bind:src="extopping.image.desktop_thumbnail"
                              width="24"
                              height="24"
                            />
                            <span class="pl-5">
                              {{
                                convertString(fixFreeToppingName(extopping.name))
                              }}
                            </span>
                          </div>
                          <div
                            class="text-right pl10 pr10 topping-price fw600 cl-525252"
                            v-if="
                              !extopping.default &&
                                getPriceTopping(extopping.prices) > 0
                            "
                          >
                            {{
                            $t('currency_display', {
                            currency: $const.CURRENCY,
                            amount: formatNumDisplay(
                            getPriceTopping(extopping.prices)
                            )
                            })
                            }}
                          </div>
                          <div class="bold text-right pl10 pr10" style="align-self: center;">
                            <span class="add-icon mr-0 ml-5 fa-plus-icon">
                              <i class="add-icon__v"></i>
                              <i class="add-icon__h"></i>
                            </span>
                            <img
                              src="../../assets/images/icons/remove.svg"
                              class="cursor-pointer fa-remove-image"
                              alt="remove"
                              style="display: none"
                            />
                          </div>
                        </div>
                      </transition-group>
                    </div>
                  </collapse>
                </div>
              </div>
            </div>
            <!-- ############################################################################ -->

            <div class="tcbox text-grey">
              <div role="button" @click="showAccordionTC = !showAccordionTC" v-if="t_c_desc">
                <h6 class="panel-title t14 fw600">
                  <i class="fa fa-caret-down" v-if="showAccordionTC"></i>
                  <i class="fa fa-caret-up" v-if="!showAccordionTC"></i>
                  <span>{{ $t('t_and_c') }}</span>
                </h6>
              </div>
              <collapse v-model="showAccordionTC" v-if="t_c_desc">
                <div class="menu-description term" style="padding: 10px">
                  <nl2br class="des" tag="p" :text="t_c_desc" />
                </div>
              </collapse>
            </div>
          </div>
          <div class="modal-action" v-if="!disableAdd">
            <div v-if="this.errorMessage">
              <span class="cl-red">{{ this.errorMessage }}</span>
            </div>
            <div>
              <button
                type="button"
                @click="preBasket()"
                class="btn btn-lg btn-success full js-action-btn-add"
                v-bind:class="{ disabled: !isValidItem() }"
                v-bind:disabled="!isValidItem()"
                v-if="product.first_layers && product.first_layers.length > 0"
              >
                {{ $t('add_to_my_basket') }}
                <span>
                  {{
                  $t('currency_display', {
                  currency: $const.CURRENCY,
                  amount: formatNumDisplay(totalPrice)
                  })
                  }}
                </span>
              </button>
              <button
                type="button"
                @click="addToBasket()"
                class="btn btn-lg btn-success full js-action-btn-add"
                v-else
              >
                {{ $t('add_to_my_basket') }}
                <span>
                  {{
                  $t('currency_display', {
                  currency: $const.CURRENCY,
                  amount: !isFreeItem(product) ? formatNumDisplay(product.price) : formatNumDisplay(0)
                  })
                  }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { MAPPED_ACTIONS } from '../../utils/map-actions'
import { mapGetters } from 'vuex'
import { auto } from 'html-webpack-plugin/lib/chunksorter'
import ModalUpsellConfirmation from '../popup/ModalUpsellConfirmation'
export default {
  props: [
    'uuid',
    'menu',
    'mfirst',
    'msecond',
    'extraCheese',
    'coupon',
    'isUpdate'
  ],
  created() {
    this.itemId = this.selectedCart ? this.selectedCart.cartId : null
    this.$cart.init(this)
    this.getTopping()

    if (!this.empty(this.mfirst) && !this.empty(this.msecond)) {
      //Not sure/convinced this is doing anything
      this.chooseVariant(this.mfirst, this.msecond)
    } else {
      this.setModalFromCartProduct()
    }
    this.eventTrackingProductDetail(
      this,
      this.menu,
      this.$const.CARD_ITEM_TYPE.SINGLE
    )

    this.fixMobileTabletScroll('OPEN')
    this.updateDisableRemoveTopping()
    this.updateDisableExtraTopping()
  },
  destroyed() {
    let sub = this.$route.params.sub
    let uuid = this.$route.params.uuid
    document.body.classList.remove('modal-open')

    this.$store.commit('setSelectedCart', null)

    if (uuid) {
      this.redirectToMenuPage(this.$route.params.slug, sub, null, true)
    } else {
      this.redirectToMenuPage(this.$route.params.slug, null, null, true)
    }

    this.fixMobileTabletScroll('CLOSE')
  },
  computed: {
    ...mapGetters({
      selectedCart: 'selectedCart'
    })
  },
  watch: {
    product(newVal, oldVal) {
      let vm = this
      this.maxExtra =
        this.product && this.product.max_extra != undefined
          ? this.product.max_extra
          : this.$const.TOPPING.MAX_EXTRA
      this.maxRemoval =
        // max_topping is actually max_removal
        this.product && this.product.max_topping != undefined
          ? this.product.max_topping
          : this.$const.TOPPING.MAX_REMOVAL
      this.t_c_desc = this.product && this.product.t_and_c
    },
    selectedCart(newVal, oldVal) {
      this.itemId = this.selectedCart ? this.selectedCart.cartId : null
    }
  },
  data: function() {
    return {
      choosedName: '',
      defaultsTopping: [],
      defaultsextraTopping: [],
      yourTopping: [],
      extraTopping: [],
      choosedFirst: {},
      choosedSecond: {},
      removeYourTopping: [],
      addExtraTopping: [],
      totalPrice: 0,
      totalExtraTopping: 0,
      transitionLeave: true,
      extChoosedCheese: '',
      extChoosedSauce: '',
      choosedCheese: {},
      choosedSauce: {},
      gotCheese: { status: 'N', price: 0 },
      maxExtra:
        (this.menu && this.menu.max_extra != undefined)
          ? this.menu.max_extra
          : this.$const.TOPPING.MAX_EXTRA,
      maxRemoval:
        // max_topping is actually max_removal
        (this.menu && this.menu.max_topping != undefined)
          ? this.menu.max_topping
          : this.$const.TOPPING.MAX_REMOVAL,
      addExtraToppingWithPrice: [],
      disableAdd: true,
      t_c_desc: this.menu && this.menu.t_and_c,
      showAccordionTC: false,
      choosedHalf: false,
      showSauceAccordion: true,
      showCheeseAccordion: true,
      showToppingMinus: true,
      showToppingPlus: true,
      product: this.menu ? this.menu : null,
      itemId: this.selectedCart ? this.selectedCart.productUuid : null,
      autoIncrement: 1,
      showCheeseChooser: true,
      showSauceChooser: true,
      errorMessage: '',
      ...MAPPED_ACTIONS
    }
  },
  mounted() {
    if (this.empty(this.mfirst) && this.empty(this.msecond)) {
      this.eventTrackingProductView(null, this.menu)
    }
  },
  methods: {
    isSpecialTopping(topping) {
      return topping.special == 1 || topping.special == 2
    },
    isFreeItem(item) {
      const vm = this
      if (vm && vm.cart && vm.cart.data && vm.cart.coupon) {
        for (let i = 0; i < vm.cart.data.length; i++) {
          if (vm.cart.coupon.data.some(x => x.menu.free.some(y => y.single_uuid === item.uuid))) {
            return true
          }
        }
      }
      return false
    },
    setModalFromCartProduct() {
      if (this.selectedCart) {
        const productInQuestion = this.selectedCart.menu.items.find(
          i => i.product_uuid === this.selectedCart.productUuid
        )

        if (productInQuestion) {
          const possibleFirst = productInQuestion.layer_info.first //This is not the same shape as first_layers[]
          const possibleSecond = productInQuestion.layer_info.second

          //Make sure size/crust is the same
          this.chooseVariant(possibleFirst, possibleSecond)

          //Remove all normal toppings
          productInQuestion.toppings.remove
            .filter(t => !this.isSpecialTopping(t))
            .map(t1 => {
              const result = this.yourTopping.find(t2 => t2.id === t1.id)

              if (!result) return
              return {
                ...result,
                quantity: t1.quantity
              }
            })
            .forEach((t, i) => {
              if (t)
                for (var x = 0; x < (t.quantity || 1); x++) {
                  this.changeTopping(t, 1, i)
                }
            })

          //Add all normal toppings
          productInQuestion.toppings.extra
            .filter(t => !this.isSpecialTopping(t))
            .map(t1 => {
              const result = this.extraTopping.find(t2 => t2.id === t1.id)

              if (!result) return
              return {
                ...result,
                quantity: t1.quantity
              }
            })
            .forEach((t, i) => {
              if (t)
                for (var x = 0; x < (t.quantity || 1); x++) {
                  this.changeTopping(t, 2, i)
                }
            })

          const possibleCheese = productInQuestion.toppings.extra.filter(
            t => t.special == 1
          )[0]
          const possibleSauce = productInQuestion.toppings.extra.filter(
            t => t.special == 2
          )[0]

          //Set cheese
          if (possibleCheese) this.chooseCheese(possibleCheese)

          //Set sauce
          if (possibleSauce) this.chooseSauce(possibleSauce)
        }
      }
    },
    chooseSauce(val) {
      // Filter only when you have value observe UI
      if (val) {
        this.choosedSauce = val
        this.updateTotalPrice()

        if (this.showSauceChooser) this.toggleSauceChooser()
      }
    },
    chooseCheese(val) {
      // Filter only when you have value observe UI
      if (val) {
        this.choosedCheese = val
        this.updateTotalPrice()

        if (this.showCheeseChooser) this.toggleCheeseChooser()
      }
    },
    hasNonSpecialToppings(toppings) {
      return (
        toppings.some(topping => !this.isSpecialTopping(topping))
      )
    },
    getProduct() {
      let vm = this
      let orderTime = this.convertDateTimeFromTimezoneToUTC(
        this.$disposition.getByKey(this, 'timezone', ''),
        this.$disposition.getByKey(this, 'collectionTimeValue', '')
      )
      if (!orderTime) {
        orderTime = moment()
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      }
      let data = {
        tier: this.$disposition.getByKey(this, 'tier', '1'),
        order_type: this.$disposition
          .getByKey(this, 'orderType', 'D')
          .charAt(0),
        order_time: orderTime,
        outlet_code: this.getOutletCode(this)
      }

      vm.axios.getMenuDetails(
        vm.uuid,
        data,
        function(response) {
          vm.product = response.data.data.item

          vm.getTopping()
        },
        function(error) {
          console.log(error)
          if (error.data) {
            vm.$notify({
              type: 'error',
              title: error.data
            })
          }
        }
      )
    },
    checkDefaultCheese(isDefault) {
      return isDefault
    },
    /**
     * Trigger before leave topping event
     */
    beforeLeaveTopping(el) {
      this.transitionLeave = false
    },
    /**
     * Trigger after leave topping event
     */
    afterLeaveTopping(el) {
      this.transitionLeave = true
    },
    /**
     * Chosen first and second layer
     */
    chooseVariant(first, second) {
      this.choosedFirst = first
      this.choosedSecond = second
      this.choosedName = first.name + ' ' + second.name
      this.updateTotalPrice()
      this.$set(this.gotCheese, 'status', this.product.extraCheese)
      this.$set(this.gotCheese, 'price', this.getCheese(first.name))
      this.disableAdd = false

      let product = this.lodash.cloneDeep(this.product)
      let choosedFirst = this.lodash.cloneDeep(first)
      let choosedSecond = this.lodash.cloneDeep(second)

      product.first_layers = []
      product.first_layers.push(choosedFirst)

      if (first != null && !this.empty(first)) {
        product.first_layers[0].second_layers = [choosedSecond]
      }

      product.price = this.totalPrice
      product.variantId = first.first_id + '-' + second.second_id
      this.eventTrackingProductView(product.variantId, product)
      this.evenTrackingProductClick(
        this,
        product,
        'product-selection-from-variant-list'
      )
      this.eventTrackingProductDetail(
        this,
        product,
        this.$const.CARD_ITEM_TYPE.SINGLE,
        'product-details'
      )
    },
    /**
     * Get cheese price belong size pizza
     * */
    getCheese(size) {
      let price = 0
      for (let cheese in this.extracheese) {
        if (this.extracheese[cheese].Size === this.convertString(size)) {
          price = this.extracheese[cheese].Price
        }
      }
      return price
    },
    /**
     * Get topping price
     * */
    getPriceTopping(topping) {
      let price = 0
      if (topping) {
        price = topping[this.choosedFirst.sku.toUpperCase()]
          ? parseInt(topping[this.choosedFirst.sku.toUpperCase()])
          : 0
      } else {
        price = 0
      }

      return price
    },
    /**
     * Reset item choiced
     * */
    resetDataItem() {
      this.choosedFirst = {}
      this.choosedName = ''
      this.totalPrice = 0
      this.removeYourTopping = []
      this.addExtraTopping = []
      this.yourTopping = JSON.parse(JSON.stringify(this.defaultsTopping))
      this.extraTopping = JSON.parse(JSON.stringify(this.defaultsextraTopping))
      this.totalExtraTopping = 0
      this.extChoosedCheese = ''
      this.extChoosedSauce = ''
      this.choosedCheese = {}
      this.choosedSauce = {}
      this.showCheeseChooser = false
      this.showSauceChooser = false
      this.$store.commit('setSelectedProduct', null)
    },
    /**
     * reset first and second layer
     * */
    changeSize() {
      //DONT reset here or else all previous changes are overwritten if you change crust
      // this.resetDataItem()

      //Manually reset first/second layer
      this.choosedFirst = {}
      this.choosedName = ''
      this.disableAdd = true
    },
    toggleSauceChooser() {
      this.showSauceChooser = !this.showSauceChooser
    },
    toggleCheeseChooser() {
      this.showCheeseChooser = !this.showCheeseChooser
    },
    /**
     * change your topping
     * type: 1: remove topping, 2 add topping
     * */
    changeTopping(topping, type, index) {
      let newTopping = this.lodash.cloneDeep(topping)
      if (topping.prices) {
        topping.price = this.getPriceTopping(topping.prices)
      } else {
        topping.price = 0
      }

      let count1 = 0
      let count2 = 0

      if (this.transitionLeave) {
        if (!topping.isDisable) {
          if (type === 1) {
            this.eventTrackingVariantUnSelect({
              item: this.menu,
              topping: topping,
              first: this.choosedFirst
            })
            // this.yourTopping.splice(index, 1)

            if (topping.default) {
              this.extraTopping.unshift(topping)
              this.removeYourTopping.push(newTopping)
              this.yourTopping = this.yourTopping.filter(function(
                value,
                index,
                arr
              ) {
                return value.sku != topping.sku
              })
            } else {
              // this.yourTopping.push(newTopping)
              this.yourTopping = this.yourTopping.filter(function(
                value,
                index,
                arr
              ) {
                if (value.sku == topping.sku) count1++ //Increment everytime we find a topping we're removing

                return value.sku != topping.sku || count1 > 1 //Keep topping if it isn't the topping we're removing or if we've already removed topping in question
              })

              this.addExtraTopping = this.addExtraTopping.filter(function(
                value,
                index,
                arr
              ) {
                if (value.sku == topping.sku) count2++ //Increment everytime we find a topping we're removing

                return value.sku != topping.sku || count2 > 1 //Keep topping if it isn't the topping we're removing or if we've already removed topping in question
              })
            }
          } else if (type === 2) {
            this.eventTrackingVariantSelect({
              item: this.menu,
              topping: topping,
              first: this.choosedFirst
            })

            if (topping.default) {
              this.yourTopping.push(topping)
              this.extraTopping = this.extraTopping.filter(function(
                value,
                index,
                arr
              ) {
                return value.sku != topping.sku
              })

              this.removeYourTopping = this.removeYourTopping.filter(function(
                value,
                index,
                arr
              ) {
                return value.sku != topping.sku
              })
            } else {
              newTopping.index = this.autoIncrement++
              this.yourTopping.unshift(newTopping)
              this.addExtraTopping.push(newTopping)
            }
          }

          this.updateTotalPrice()
          this.setActiveToppingList(this.extraTopping, this.yourTopping)
          this.checkDisableTopping(topping, type)
        }
      }
    },

    /**
     * update total price
     * */
    updateTotalPrice() {
      this.totalExtraTopping = 0
      for (let topping in this.addExtraTopping) {
        this.totalExtraTopping += parseFloat(
          this.getPriceTopping(this.addExtraTopping[topping].prices)
        )
      }
      this.totalPrice =
        this.product.price > 0 ? parseFloat(this.product.price) : 0
      this.totalPrice += this.totalExtraTopping
      if (typeof this.choosedFirst.price !== 'undefined') {
        this.totalPrice += parseFloat(
          this.choosedFirst.price > 0 ? this.choosedFirst.price : 0
        )
      }
      if (typeof this.choosedSecond.price !== 'undefined') {
        this.totalPrice += parseFloat(
          this.choosedSecond.price > 0 ? this.choosedSecond.price : 0
        )
      }
      let extraPrice = 0
      // for (let i in this.choosedCheese) {
      if (this.choosedCheese && this.choosedCheese.prices) {
        extraPrice += parseFloat(
          this.choosedCheese.prices[this.choosedFirst.sku]
        )
      }
      if (this.choosedSauce && this.choosedSauce.prices) {
        extraPrice += parseFloat(
          this.choosedSauce.prices[this.choosedFirst.sku]
        )
      }
      this.totalPrice += parseFloat(extraPrice)
    },
    /**
     * Slice topping
     * type: 1: your topping, 2 remove your topping, 3 add topping (default)
     * */
    sliceTopping(topping, index, type) {
      if (type === 1) {
        for (let y in this.yourTopping) {
          if (this.yourTopping[y].id === topping.id) {
            this.yourTopping.splice(y, 1)
            break
          }
        }
      } else if (type === 2) {
        for (let y in this.removeYourTopping) {
          if (this.removeYourTopping[y].id === topping.id) {
            this.removeYourTopping.splice(y, 1)
            break
          }
        }
      } else {
        for (let y in this.addExtraTopping) {
          if (this.addExtraTopping[y].id === topping.id) {
            this.addExtraTopping.splice(y, 1)
            break
          }
        }
      }
    },
    disableOrEnableAddNow() {
      this.disableAdd = this.product.first_layers !== null && this.product.first_layers.length > 0
    },
    /**
     * set your and extra toppings
     * */
    getTopping() {
      let vm = this
      if (
        this.product &&
        this.product.toppings &&
        this.product.toppings.default &&
        this.product.toppings.extra
      ) {
        let data = this.lodash.uniqWith(
          this.product.toppings.default,
          this.lodash.isEqual
        )
        let autoIncrement = 0
        for (let topping in data) {
          data[topping].default = true
          data[topping].isDisable = false
          data[topping].index = autoIncrement++
        }

        this.yourTopping = data
          ? this.lodash.uniqBy(
              data.filter(x => !this.isSpecialTopping(x)),
              'id'
            )
          : []
        this.choosedCheese = data ? data.filter(x => x.special == 1)[0] : null
        this.choosedSauce = data ? data.filter(x => x.special == 2)[0] : null
        let _extraTopping = JSON.parse(
          JSON.stringify(this.product.toppings.extra)
        )
        this.extraTopping = _extraTopping
          ? this.lodash.uniqWith(
              _extraTopping
                .concat(data.filter(z => this.isSpecialTopping(z)))
                .filter(
                  x => vm.yourTopping.filter(y => y.sku === x.sku).length == 0
                ),
              (x, y) => x.sku === y.sku
            )
          : []

        this.defaultsTopping = JSON.parse(JSON.stringify(this.yourTopping))
        this.defaultsextraTopping = JSON.parse(
          JSON.stringify(this.extraTopping)
        )

        this.updateDisableExtraTopping()
      }

      vm.disableOrEnableAddNow()
    },
    /**
     * check topping is extra or default
     * type: 1 isExtra, 2 Default
     * */
    checkToppingType(topping, type) {
      let i = 0
      for (let t in this.defaultsTopping) {
        if (this.defaultsTopping[t].id === topping.id) {
          i++
        }
      }
      if (i === 0) {
        return type === 1
      }
      i = 0
      for (let t in this.yourTopping) {
        if (this.yourTopping[t].id === topping.id) {
          i++
        }
      }
      if (type === 1) {
        return i > 0
      }
      return i === 0
    },
    /**
     * re-update extra and default toppings
     * type: 1 remove topping, 2 add topping
     * */
    updateExtra({ topping, type }) {
      for (let t in this.extraTopping) {
        if (this.extraTopping[t].id === topping.id) {
          if (type === 1) {
            if (topping.default && this.yourTopping.length > 0) {
              this.extraTopping[t].default = true
              for (let y in this.yourTopping) {
                if (this.yourTopping[y].id === topping.id) {
                  this.yourTopping[y].default = true
                  this.extraTopping[t].default = false
                  break
                }
              }
            } else {
              this.extraTopping[t].default = topping.default
            }
          } else {
            this.extraTopping[t].default = false
          }
          break
        }
      }
    },
    /**
     * Check to show/hide extra topping
     * type: 1 remove topping, 2 add topping (default)
     * */
    checkDisableTopping(topping, type) {
      this.updateDisableRemoveTopping(topping, type)
      this.updateDisableExtraTopping(topping, type)
    },
    isExtraTopping(topping) {
      return !topping.default && topping.special == 0
    },
    disableExtraTopping(topping) {
      if (topping) {
        topping.isDisable = true
      }
    },
    /**
     * Checking to show/hide extra topping
     * */ 
    updateDisableExtraTopping(topping, type) {
      const disable =
        this.yourTopping.filter(this.isExtraTopping).length >= this.maxExtra
      this.extraTopping
        .filter(this.isExtraTopping)
        .forEach(topping => (topping.isDisable = disable || topping.isDisable))
      this.disableExtraTopping(topping)
    },
    updateDisableRemoveTopping(topping, type) {
      const disable = this.removeYourTopping.length >= this.maxRemoval
      this.yourTopping.forEach(
        topping => (topping.isDisable = disable && topping.default)
      )
    },
    /**
     * Show extra topping
     * */
    disableExtraToppings(flag) {
      this.extraTopping.forEach(topping => (topping.isDisable = !flag))
    },
    isValidItem() {
      const extraToppings = this.yourTopping.filter(this.isExtraTopping)
      if (extraToppings.length < this.product.min_extra) {
        this.errorMessage = this.$t('needs_more_extras', {
          more: this.product.min_extra - extraToppings.length
        })
        return false
      }
      if (extraToppings.length > this.maxExtra) {
        this.errorMessage = this.$t('needs_fewer_extras', {
          more: extraToppings.length - this.maxExtra
        })
        return false
      }
      this.errorMessage = ''
      return true
    },
    getToppingById(toppings) {
      return toppings.map(topping => topping.id)
    },

    checkSubmit() {
      if (
        this.product &&
        this.product.first_layers &&
        this.product.first_layers.length > 0
      ) {
        if (this.choosedFirst && this.choosedFirst.uuid) {
          return true
        } else {
          return false
        }
      } else if (this.product && this.product.uuid) {
        return true
      }
      return false
    },
    fixFreeToppingName(name) {
      return name.split('-')[0]
    },
    closeAndResetStoreState() {
      this.$emit('close');
      this.$store.commit('setSelectedProduct', null)
    },
    preBasket() {
      if (this.choosedSecond.name === 'Pan Pizza de Sartén') {
        const costraCheddar = this.extraTopping.find(
          t => t.sku === 'costra-cheddar'
        )
        if (costraCheddar && !this.addExtraTopping.map(t => t.sku).includes('costra-cheddar')) {
          this.$modal.show(ModalUpsellConfirmation, {
            checkout: this.addToBasket,
            name: costraCheddar.name,
            toppingId: costraCheddar.id,
            currency: this.$const.CURRENCY,
            amount: this.getPriceTopping(costraCheddar.prices)
          })
        } else {
          this.addToBasket()
        }
      } else {
        this.addToBasket()
      }
    },

    /**
     * Adding item to cart
     * Prepare new format to API
     * */
    addToBasket(upsellToppingIds = []) {
      this.$store.dispatch('setUserAction', this.ADD_TO_CART)
      if (!this.isValidItem()) {
        return
      }
      let defaultToppings = this.product.toppings.default ? JSON.parse(
        JSON.stringify(this.product.toppings.default)
      ) : undefined
      if (this.checkSubmit()) {
        let item = {
          group: {},
          selling_start: this.product.selling_start,
          products: []
        }

        if (this.coupon) {
          item.group.coupon_code = this.coupon
        }
        item.group['outlet_code'] = this.getOutletCode(this)
        item.group['tier'] = this.$cart.tier
        item.group['order_type'] = this.$cart.order_type
        item.group['order_time'] = this.getTimePickerDate(this)
        item.group['product_type'] = this.product.product_type
        item.group['combo_id'] = null
        item.group['quantity'] = 1
        item.group['category_uuid'] = this.$cart.category_id

        let product = {}
        product.category_id = this.$cart.category_id
        product.combo_group_id = ''
        product.product_id = this.product.uuid
        product.first_id = this.choosedFirst.uuid
        product.second_id = this.choosedSecond.uuid
        product.toppings = {}
        product.toppings.remove = this.$cart.getToppingById(
          this.removeYourTopping
        )
        // Separate default toppings and extra toppings before sending to cart
        let newExtraTopping = this.addExtraTopping
        if (
          defaultToppings &&
          defaultToppings.filter(x => x.sku === this.choosedCheese.sku)
            .length == 0
        ) {
          newExtraTopping = this.lodash.union(newExtraTopping, [
            this.choosedCheese
          ])
        }
        if (
          defaultToppings &&
          defaultToppings.filter(x => x.sku === this.choosedSauce.sku).length ==
            0
        ) {
          newExtraTopping = this.lodash.union(newExtraTopping, [
            this.choosedSauce
          ])
        }
        product.toppings.extra = this.$cart.getToppingById(newExtraTopping)
        if (upsellToppingIds && upsellToppingIds.length > 0) {
          product.toppings.extra = [
            ...product.toppings.extra,
            ...upsellToppingIds
          ]
        }
        item.products.push(product)
        this.$emit('close')
        // console.log(JSON.stringify(item)) // FIXME: DEBUGGER
        if (this.itemId) {
          item.group['item_id'] = this.itemId
          this.$cart.updateCart(this, item)
        } else {
          this.$cart.insert(this, item)
        }
        let pro = {
          item: this.menu,
          id: this.product.uuid,
          name: this.product.name,
          price: this.totalPrice,
          first: this.choosedFirst || {},
          second: this.choosedSecond || {},
          category: 'Pizzas'
        }
        this.resetDataItem()

        this.eventTrackingAddToCart(
          this,
          pro,
          'main',
          this.$const.CARD_ITEM_TYPE.SINGLE
        )
      }
    }
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btn-half {
  margin: 0px 0px 15px;
}

.remove-toppings-str {
  display: block;
}

.add-toppings-str {
  display: block;
}
</style>
